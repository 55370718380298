










































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { StructureCreateModel } from "@/api/models/structures/structure";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "create-structure",
})
export default class CreateStructure extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewStructureSidebarActive!: boolean;
  loading = false;

  required = required;

  newStructure: StructureCreateModel = {};

  resetForm() {
    this.newStructure = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.structures
      .post(this.newStructure)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'une structure",
            text: "Structure créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-structures");
        this.$emit("update:is-add-new-structure-sidebar-active", false);
        this.loading = false;
      });
  }
}
