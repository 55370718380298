









































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Structure, UpdateStructureModel } from "@/api/models/structures/structure";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "update-structure",
})
export default class EditStructure extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateStructureSidebarActive!: boolean;
  @Prop({ required: true }) structureId!: string;

  loading = false;
  required = required;
  loadingStructure = false;



  structure: UpdateStructureModel = {};

  async created() {
    console.log("update created")
    this.loadingStructure = true
    await this.$http.myressif.structures
      .getById(this.structureId)
      .then((res: Structure) => {
        this.structure = res;
        this.loadingStructure = false;
      });
  }

  resetForm() {
    this.structure = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.structures
      .update(
        this.structureId,
        this.structure
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification de la structure",
            text: "Structure modifiée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-structures");
        this.$emit("update:is-update-structure-sidebar-active", false);
        this.loading = false;
      });
  }
}
