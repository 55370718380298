export class PaginatedList<T> {

  constructor() {
    this.items = []
    this.hasNextPage = false
    this.hasPreviousPage = false
    this.pageIndex = 0
    this.totalCount = 0
    this.totalPages = 0
  }
  
  items: T[]
  hasNextPage: boolean
  hasPreviousPage: boolean
  pageIndex: number
  totalCount: number
  totalPages: number   
}